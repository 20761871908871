import $ from 'jquery';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import GLightbox from 'glightbox';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { $Elements, Animations, Events } from './constants';
import { fixMobileHover, sortAnimations, initGTM, setCookiesAllowed, setDisclaimerAccepted, scrollTo } from './helpers';

// ---------- LazyLoad Config ----------

lazySizes.cfg.throttleDelay = 66;
lazySizes.cfg.preloadAfterLoad = true;

// ---------- Fix Scroll ----------

history.scrollRestoration = "manual";

// ---------- jQuery Related ----------

$Elements.document.on(Events.page.enter, () => {

	// ---------- Fix VH ----------

	$Elements.window.on('resize', () => document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`));
	$Elements.window.trigger('resize');

	// ---------- Fix Mobile Hover ----------

	$Elements.window.on('load', () => fixMobileHover());

	// ---------- Handle Aniamtions ----------

	ScrollTrigger.matchMedia(sortAnimations(Animations));

	// ---------- Links ----------

	$Elements.body.on('click', 'a[href="#"], .void-link', (e) => e.preventDefault());

	$Elements.body.on('click', '[data-scroll-to], [target="scroll-to"]', function(e) {
		e.preventDefault();

		const self = $(this);
		const target = (self.is('a')) ? $(self.attr('href')) : $(self.data('scroll-to'));

		if(target.length) {
			scrollTo(target);
		}
	});

	$Elements.body.on('click', '.select-dropdown .dropdown-menu .btn', function() {
		$(this).closest('.dropdown').find('.dropdown-toggle').html($(this).text());
	});

	// ---------- GLightbox ----------

	$('[target="lightbox"]').each(function() {

		GLightbox({
			selector: 'a[href="' + this.attributes.href.value + '"][target="lightbox"]',
			openEffect: 'fade',
			closeEffect: 'fade',
			videosWidth: 1280,
		});
	});

	$('[target^="lightbox-gallery"]').each(function() {

		GLightbox({
			selector: 'a[target="' + this.attributes.target.value + '"]',
			openEffect: 'fade',
			closeEffect: 'fade',
			videosWidth: 1280,
		});
	});

	// ---------- Disclaimer ----------

	$('#disclaimer').on('close.bs.alert', function() {
		setDisclaimerAccepted();
		$Elements.body.removeClass('disclaimer-open');
	});

	// ---------- Cookies ----------

	initGTM();

	$('#cookies-allowed').on('click', '[data-bs-dismiss="alert"]', function() {
		setCookiesAllowed(this.dataset.allowed);
	});

});
